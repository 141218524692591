import styled, { css } from 'styled-components'
import { color, contrastingFontColor, unit } from 'ui/themes/utils'

import I from 'ui/elements/I'

export const Icon = styled(I)`
  ${(props) => props.hover && css`
    cursor: pointer;
  `}

  ${(props) => {
    if (!props.color) return

    return css`
      color: ${color(props.color)};
    `
  }}

  ${(props) => props.size && css`
    font-size: ${unit(props.size)};
  `}

  ${(props) => props.rounded && css`
    width: auto;

    &:before {
      background: ${color(props.backgroundColor)};
      color: ${contrastingFontColor(color(props.backgroundColor)(props))};
      font-size: 11px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      display: block;
    }
  `}
`
