import { color, size, fontSize, fontWeight, unit } from 'ui/themes/utils'

const modifiersCache = new WeakMap()

// This call is expensive, so we cache the result
// in modifiersCache, to avoid calling it on
// every rerender.
const getModifiers = (theme) => {
  const horizontalPositions = ['right', 'left']
  const verticalPositions = ['top', 'bottom']
  const positions = horizontalPositions.concat(verticalPositions)
  const borderPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
  const spacings = ['padding', 'margin']
  const dimensions = ['width', 'height']
  const screenSizes = theme.size.screenSizes
  const displayProperties = [
    'none',
    'inherit',
    'block',
    'contents',
    'flex',
    'inline-flex',
    'grid',
    'inline',
    'inline-block',
    'revert',
    'table',
    'unset'
  ]

  const modifiers = new Map([
    ['text-shadow', () => `
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    `],
    ['text-line-through', () => `
      text-decoration: line-through;
    `],
    ['text-decoration-none', () => `
      text-decoration: none;
    `],
    ['text-center', () => `
      text-align: center;
    `],
    ['text-right', () => `
      text-align: right;
    `],
    ['text-left', () => `
      text-align: left;
    `],
    ['text-lowercase', () => `
      text-transform: lowercase;
    `],
    ['text-uppercase', () => `
      text-transform: uppercase;
    `],
    ['position-relative', () => `
      position: relative;
    `],
    ['position-absolute', () => `
      position: absolute !important;
    `],
    ['fixed-background', (props) => `
      background-color: ${props.background};
    `],
    ['no-border', () => `
      border: none !important;
    `],
    ['no-overflow', () => `
      overflow: hidden !important;
    `],
    ['preserve-newlines', () => `
      white-space: pre-wrap;
    `],
    ['break-words', () => `
      word-break: break-all;
    `],
    ['flex-row', () => `
      flex-direction: row;
    `],
    ['flex-column', () => `
      flex-direction: column;
    `],
    ['flex-wrap', () => `
      flex-wrap: wrap;
    `],
    ['flex-nowrap', () => `
      flex-wrap: nowrap;
    `],
    ['flex-grow', () => `
      flex-grow: 1;
    `],
    ['justify-start', () => `
      justify-content: flex-start;
    `],
    ['justify-end', () => `
      justify-content: flex-end;
    `],
    ['justify-center', () => `
      justify-content: center;
    `],
    ['justify-between', () => `
      justify-content: space-between;
    `],
    ['justify-around', () => `
      justify-content: space-around;
    `],
    ['align-start', () => `
      align-items: flex-start;
    `],
    ['align-end', () => `
      align-items: flex-end;
    `],
    ['align-center', () => `
      align-items: center;
    `],
    ['v-align-middle', () => `
      vertical-align: middle;
    `],
    ['rounded-circle', () => `
      border-radius: 50%;
    `],
    ['text-break', () => `
      word-break: break-word !important;
      overflow-wrap: break-word !important;
    `],
    ['text-nowrap', () => `
      white-space: nowrap;
    `],
    ['margin-x-auto', () => `
      margin-left: auto;
      margin-right: auto;
    `],
    ['overflow-hidden', () => `
      overflow: hidden;
    `],
    ['overflow-auto', () => `
      overflow: auto;
    `],
    ['select-none', () => `
      user-select: none;
    `],
    ['grid-2-columns', () => `
      grid-template-columns: 1fr 1fr;
    `],
    ['list-style-none', () => `
      list-style: none;
    `],
    ['container-normal', () => `
      container-type: normal;
    `],
    ['container-inline-size', () => `
      container-type: inline-size;
    `],
    ['container-inline', () => `
      container-type: inline;
    `]
  ])

  Object.keys(screenSizes).forEach((screenSize) => {
    // Responsive right text align properties
    // Example text-right-md
    modifiers.set(`text-right-${screenSize}`, () => `
      @media (min-width: ${screenSizes[screenSize]}) {
        text-align: right !important;
      }
    `)
    // Responsive left text align properties
    // Example text-left-lg
    modifiers.set(`text-left-${screenSize}`, () => `
      @media (min-width: ${screenSizes[screenSize]}) {
        text-align: left !important;
      }
    `)
    // Responsive fixed position
    // Example position-fixed-lg
    modifiers.set(`position-fixed-${screenSize}`, () => `
      @media (min-width: ${screenSizes[screenSize]}) {
        position: fixed !important;
      }
    `)
  })

  // Limits a text to be a given amount of lines and adds ellipsis if necessary
  // Example: line-clamp-2 limits a text to 2 lines
  Array(3).fill().forEach((_, index) => {
    modifiers.set(`line-clamp-${index + 1}`, () => `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${index + 1};
      -webkit-box-orient: vertical;
    `)
  })

  // Width/height properties
  dimensions.forEach((dimension) => {
    Array(100).fill().forEach((_, index) => {
      // Example: width-100p / height-100p
      // Supports 1 till 100
      modifiers.set(`${dimension}-${index + 1}p`, () => `
        ${dimension}: ${index + 1}% !important;
      `)

      // Example: max-width-100p / max-height-100p
      // Supports 1 till 100
      modifiers.set(`max-${dimension}-${index + 1}p`, () => `
        max-${dimension}: ${index + 1}% !important;
      `)

      // Example: max-width-100vh / max-height-100vh
      // Supports 1 till 100
      modifiers.set(`max-${dimension}-${index + 1}v${dimension[0]}`, () => `
        max-${dimension}: ${index + 1}v${dimension[0]} !important;
      `)

      // Responsive maximum width/height
      // Example: max-width-75p-md
      Object.keys(screenSizes).forEach((screenSize) => {
        modifiers.set(`max-${dimension}-${index + 1}p-${screenSize}`, () => `
          @media (min-width: ${screenSizes[screenSize]}) {
            max-${dimension}: ${index + 1}% !important;
          }
        `)
      })
    })

    // A minimum width/height of X %
    // Example: min-width-100p
    // Supports 1 till 100
    Array(100).fill().forEach((_, index) => {
      modifiers.set(`min-${dimension}-${index + 1}p`, () => `
        min-${dimension}: ${index + 1}% !important;
      `)

      // Responsive minimum width/height
      // Example: min-width-75p-md
      Object.keys(screenSizes).forEach((screenSize) => {
        modifiers.set(`min-${dimension}-${index + 1}p-${screenSize}`, () => `
          @media (min-width: ${screenSizes[screenSize]}) {
            min-${dimension}: ${index + 1}% !important;
          }
        `)
      })
    })

    // Auto width/height
    // Example: width-auto
    modifiers.set(`${dimension}-auto`, () => `
      ${dimension}: auto !important;
    `)

    // vh/vw width/height
    // Example: height-100vh, width-100vw
    modifiers.set(`${dimension}-100v${dimension[0]}`, () => `
      ${dimension}: 100v${dimension[0]} !important;
    `)
  })

  // Control the width/height of an element, supports 25 till 1500, in steps of 25
  // Example: width-150
  // Example: max-width-650
  // Example: min-height-50
  Array(60).fill().forEach((_, index) => {
    const size = (index + 1) * 25

    modifiers.set(`width-${size}`, () => `
      width: ${size}px;
    `)
    modifiers.set(`max-width-${size}`, () => `
      max-width: ${size}px;
    `)
    modifiers.set(`height-${size}`, () => `
      height: ${size}px;
    `)
    modifiers.set(`min-height-${size}`, () => `
      min-height: ${size}px;
    `)
    modifiers.set(`min-width-${size}`, () => `
      min-width: ${size}px;
    `)

    // Responsive width properties
    // width-{breakpoint}-{size}
    // Example width-md-250
    Object.keys(screenSizes).forEach((screenSize) => {
      modifiers.set(`width-${screenSize}-${size}`, () => `
        @media (min-width: ${screenSizes[screenSize]}) {
          width: ${size}px;
        }
      `)
    })
  })

  // Font weight, example: font-weight-lighter
  Object.keys(theme.font.weight).forEach((weight) => {
    modifiers.set(`font-weight-${weight}`, (props) => `
      font-weight: ${fontWeight(weight)(props)} !important;
    `)
  })

  // Font size, example: font-size-lg
  Object.keys(theme.font.size).forEach((size) => {
    modifiers.set(`font-size-${size}`, (props) => `
      font-size: ${fontSize(size)(props)} !important;
    `)
  })

  // Font size, example: font-size-12
  Object.keys(theme.units).forEach((unitSize) => {
    modifiers.set(`font-size-${unitSize}`, (props) => `
      font-size: ${unit(unitSize)(props)} !important;
    `)
  })

  // Line height, example: line-height-lg
  Object.keys(theme.font.size).forEach((size) => {
    modifiers.set(`line-height-${size}`, (props) => `
      line-height: ${fontSize(size)(props)} !important;
    `)
  })

  // Line height, example: line-height-6
  Object.keys(theme.units).forEach((unitSize) => {
    modifiers.set(`line-height-${unitSize}`, (props) => `
      line-height: ${unit(unitSize)(props)} !important;
    `)
  })

  // Box shadows, example: box-shadow-lg
  Object.keys(theme.size.shadow).forEach((shadowSize) => {
    modifiers.set(`box-shadow-${shadowSize}`, (props) => `
      box-shadow: ${size('shadow', shadowSize)(props)} !important;
    `)
  })

  // Border radius, example: border-radius-lg
  Object.keys(theme.size.borderRadius).forEach((radiusSize) => {
    modifiers.set(`border-radius-${radiusSize}`, (props) => `
      border-radius: ${size('borderRadius', radiusSize)(props)} !important;
    `)
  })

  // Specific position border radius, example: border-top-left-radius-lg
  borderPositions.forEach((position) => {
    Object.keys(theme.size.borderRadius).forEach((radiusSize) => {
      modifiers.set(`border-${position}-radius-${radiusSize}`, (props) => `
        border-${position}-radius: ${size('borderRadius', radiusSize)(props)} !important;
      `)
    })
  })

  // Disable border radius, example: no-border-radius-bottom-left
  borderPositions.forEach((position) => {
    modifiers.set(`no-border-radius-${position}`, () => `
      border-${position}-radius: 0 !important;
    `)
  })

  positions.forEach((position) => {
    // Borders on specific sides, example: border-bottom
    modifiers.set(`border-${position}`, (props) => `
      border-${position}: 1px solid ${color('Misc/Divider')(props)} !important;
    `)

    // Disable borders, example: no-border-bottom
    modifiers.set(`no-border-${position}`, () => `
      border-${position}: none !important;
    `)
  })

  Object.keys(theme.color).forEach((themeColor) => {
    // Text color, example: text-white
    modifiers.set(`text-${themeColor}`, (props) => `
      color: ${color(themeColor)(props)} !important;
    `)

    // Border with color, example: border-Primary/Base
    modifiers.set(`border-${themeColor}`, (props) => `
      border: 1px solid ${color(themeColor)(props)} !important;
    `)

    // Border color, example: border-color-Primary/Base
    modifiers.set(`border-color-${themeColor}`, (props) => `
      border-color: ${color(themeColor)(props)} !important;
    `)

    // Background color, example: background-color-Primary/Base
    modifiers.set(`background-color-${themeColor}`, (props) => `
      background-color: ${color(themeColor)(props)} !important;
    `)
  })

  // Example: gap-xl
  // Defined as: <Div modifiers={'gap-xl'} />
  Object.keys(theme.size.padding).forEach((paddingSize) => {
    modifiers.set(`gap-${paddingSize}`, (props) => `
      gap: ${size('padding', paddingSize)(props)} !important;
    `)
  })

  // Example: gap-2
  // Defined as: <Div modifiers={'gap-2'} />
  Object.keys(theme.units).forEach((unitSize) => {
    modifiers.set(`gap-${unitSize}`, (props) => `
      gap: ${unit(unitSize)(props)} !important;
    `)
  })

  spacings.forEach((spacingName) => {
    // Example (padding): padding: 10px;
    // Defined as: <Div modifiers={'padding'} size="xl" />
    modifiers.set(spacingName, (props) => `
      ${spacingName}: ${size(spacingName)(props)};
    `)

    // Example: padding-xl
    // Defined as: <Div modifiers={'padding-xl'} />
    Object.keys(theme.size[spacingName]).forEach((paddingSize) => {
      modifiers.set(`${spacingName}-${paddingSize}`, (props) => `
        ${spacingName}: ${size(spacingName, paddingSize)(props)} !important;
      `)
    })

    // Example: padding-2
    // Defined as: <Div modifiers={'padding-2'} />
    Object.keys(theme.units).forEach((unitSize) => {
      modifiers.set(`${spacingName}-${unitSize}`, (props) => `
        ${spacingName}: ${unit(unitSize)(props)} !important;
      `)
    })

    // Example: padding-x-2
    // Defined as: <Div modifiers={'padding-x-2'} />
    Object.keys(theme.units).forEach((unitSize) => {
      modifiers.set(`${spacingName}-x-${unitSize}`, (props) => `
        ${spacingName}-left: ${unit(unitSize)(props)} !important;
        ${spacingName}-right: ${unit(unitSize)(props)} !important;
      `)
    })

    // Example: padding-y-2
    // Defined as: <Div modifiers={'padding-y-2'} />
    Object.keys(theme.units).forEach((unitSize) => {
      modifiers.set(`${spacingName}-y-${unitSize}`, (props) => `
        ${spacingName}-top: ${unit(unitSize)(props)} !important;
        ${spacingName}-bottom: ${unit(unitSize)(props)} !important;
      `)
    })

    // Example (no-padding): padding: 0;
    modifiers.set(`no-${spacingName}`, () => `
      ${spacingName}: 0 !important;
    `)

    if (spacingName === 'margin') {
      // Auto margins
      // Example (margin-top-auto): margin-top: auto;
      positions.forEach((position) => {
        modifiers.set(`${spacingName}-${position}-auto`, () => `
          margin-${position}: auto !important;
        `)
      })
    }

    positions.forEach((position) => {
      Object.keys(theme.size[spacingName]).forEach((paddingSize) => {
        // Example (margin-top-sm): margin-top: 10px;
        modifiers.set(`${spacingName}-${position}-${paddingSize}`, (props) => `
          ${spacingName}-${position}: ${size(spacingName, paddingSize)(props)} !important;
        `)

        // Negative margin
        // Example (margin-top-n-sm): margin-top: -10px;
        modifiers.set(`${spacingName}-${position}-n-${paddingSize}`, (props) => `
          ${spacingName}-${position}: -${size(spacingName, paddingSize)(props)} !important;
        `)

        // Responsive margins
        // {property}-{sides}-{breakpoint}-{size}
        // Example (margin-top-md-sm): margin-top: 10px;
        Object.keys(screenSizes).forEach((screenSize) => {
          modifiers.set(`${spacingName}-${position}-${screenSize}-${paddingSize}`, (props) => `
            @media (min-width: ${screenSizes[screenSize]}) {
              ${spacingName}-${position}: ${size(spacingName, paddingSize)(props)} !important;
            }
          `)
        })

        Object.keys(theme.units).forEach((unitSize) => {
          // Example: margin-top-2
          // Defined as: <Div modifiers={'margin-top-2'} />
          modifiers.set(`${spacingName}-${position}-${unitSize}`, (props) => `
            ${spacingName}-${position}: ${unit(unitSize)(props)} !important;
          `)

          // Negative margin
          // Example: margin-top-n-2
          modifiers.set(`${spacingName}-${position}-n-${unitSize}`, (props) => `
            ${spacingName}-${position}: -${unit(unitSize)(props)} !important;
          `)
        })
      })

      // Example (no-margin-bottom): margin: 0;
      modifiers.set(`no-${spacingName}-${position}`, () => `
        ${spacingName}-${position}: 0 !important;
      `)

      // Responsive disabling margins
      // Example no-margin-top-md
      Object.keys(screenSizes).forEach((screenSize) => {
        modifiers.set(`no-${spacingName}-${position}-${screenSize}`, () => `
          @media (min-width: ${screenSizes[screenSize]}) {
            ${spacingName}-${position}: 0 !important;
          }
        `)
      })
    })
  })

  // Response floats
  horizontalPositions.forEach((position) => {
    modifiers.set(`float-${position}`, () => `
      float: ${position} !important;
    `)

    Object.keys(screenSizes).forEach((screenSize) => {
      modifiers.set(`float-right-${screenSize}`, () => `
        @media (min-width: ${screenSizes[screenSize]}) {
          float: right !important;
        }
      `)
    })
  })

  displayProperties.forEach((displayProp) => {
    modifiers.set(`display-${displayProp}`, () => `
      display: ${displayProp} !important;
    `)

    // Responsive display properties
    // display-{breakpoint}-{displayProp}
    // Example display-md-block
    Object.keys(screenSizes).forEach((screenSize) => {
      modifiers.set(`display-${screenSize}-${displayProp}`, () => `
        @media (min-width: ${screenSizes[screenSize]}) {
          display: ${displayProp} !important;
        }
      `)
    })
  })

  return modifiers
}

export default (modifiers) => {
  if (modifiersCache.has(modifiers)) {
    return modifiersCache.get(modifiers)
  }

  const computedModifiers = getModifiers(modifiers)

  modifiersCache.set(modifiers, computedModifiers)

  return computedModifiers
}
