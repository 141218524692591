// React
import React from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BLoader from 'ui/blocks/Loader'
import Icon from 'ui/components/Icon'

// Shared

/**
 * Block: Loader.
 *
 * @example
 *   <Loader size="md" color="primary" />
 */
const Loader = ({ size, color, style }) => {
  return (
    <BLoader size={size} color={color} data-tid="Loader">
      <Icon icon="spinner-third" spin style={style} />
    </BLoader>
  )
}

Loader.displayName = 'Loader'

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.string
}

export default Loader
