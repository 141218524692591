

import { transparentize } from './utils'

/**
 * Figma: https://www.figma.com/file/j4OaSht5DUOkPEcthfjc5x/Style-tokens
 */

export const palette = {
  blue: {
    5: '#EDF5FF',
    10: '#E2EFFF',
    20: '#DDEBFF',
    30: '#BBDBFA',
    40: '#86C2FF',
    50: '#86C2FF',
    60: '#136DEB',
    70: '#2466C3',
    80: '#1B3D6B',
    90: '#2C3847'
  },
  green: {
    5: '#E8FFEB',
    10: '#D8FFDD',
    20: '#C7FFCF',
    30: '#B1FCC1',
    40: '#96FFAD',
    50: '#62DA7C',
    60: '#40A14F',
    70: '#1F772C',
    80: '#0E491B',
    90: '#2D4030'
  },
  orange: {
    5: '#FFF2DE',
    10: '#FFEACB',
    20: '#FFE1B4',
    30: '#FFDAA1',
    40: '#FFCF86',
    50: '#FFB442',
    60: '#E08700',
    70: '#A34C00',
    80: '#593B0D',
    90: '#423B2F'
  },
  red: {
    5: '#FFEDEF',
    10: '#FFE5E8',
    20: '#FFE0E3',
    30: '#FFB7B7',
    40: '#FF8181',
    50: '#FF4252',
    60: '#E51C2C',
    70: '#C1000F',
    80: '#670C13',
    90: '#4A3436'
  },
  purple: {
    5: '#F7F2FF',
    10: '#F0E7FF',
    20: '#EADEFF',
    30: '#DDC8FF',
    40: '#C6A3FF',
    50: '#966BDB',
    60: '#623AA3',
    70: '#4C248D',
    80: '#442872',
    90: '#3B324A'
  },
  grey: {
    5: '#F4F9FF',
    10: '#EDF1F5',
    20: '#E0E4E8',
    30: '#D6D9DB',
    40: '#A4A7A8',
    50: '#6C727A',
    60: '#4F5661',
    70: '#333A40',
    80: '#2D3035',
    90: '#1F2326'
  },
  black: '#131314',
  white: '#FFFFFF'
}

export const colors = {
  /**
   * Theme colors
   */
  'Primary/Base': palette.blue[60],
  'Primary/Highlight': palette.blue[70],
  'Primary/Background1': palette.blue[5],
  'Primary/Background2': palette.blue[20],
  'Primary/Foreground1': palette.white,
  'Primary/Foreground2': palette.blue[60],
  'Primary/Foreground3': palette.blue[60],
  'Primary/Outline': palette.blue[60],
  'Primary/Controlvariant1': palette.blue[60],
  'Primary/Controlvariant2': palette.blue[5],

  'Secondary/Base': palette.white,
  'Secondary/Highlight': palette.grey[5],
  'Secondary/Background1': palette.grey[5],
  'Secondary/Background2': palette.grey[20],
  'Secondary/Foreground1': palette.black,
  'Secondary/Foreground2': palette.black,
  'Secondary/Foreground3': palette.black,
  'Secondary/Outline': palette.grey[30],
  'Secondary/Controlvariant1': palette.grey[30],
  'Secondary/Controlvariant2': palette.grey[5],

  'Tertiary/Base': palette.white,
  'Tertiary/Highlight': palette.grey[5],
  'Tertiary/Background1': palette.white,
  'Tertiary/Background2': palette.white,
  'Tertiary/Foreground1': palette.black,
  'Tertiary/Foreground2': palette.black,
  'Tertiary/Foreground3': palette.black,
  'Tertiary/Outline': palette.grey[30],
  'Tertiary/Controlvariant1': palette.white,
  'Tertiary/Controlvariant2': palette.white,

  'Success/Base': palette.green[50],
  'Success/Highlight': palette.green[60],
  'Success/Background1': palette.green[5],
  'Success/Background2': palette.green[20],
  'Success/Foreground1': palette.black,
  'Success/Foreground2': palette.green[60],
  'Success/Foreground3': palette.green[70],
  'Success/Outline': palette.green[70],
  'Success/Controlvariant1': palette.green[50],
  'Success/Controlvariant2': palette.green[5],

  'Warning/Base': palette.orange[50],
  'Warning/Highlight': palette.orange[60],
  'Warning/Background1': palette.orange[5],
  'Warning/Background2': palette.orange[20],
  'Warning/Foreground1': palette.black,
  'Warning/Foreground2': palette.orange[60],
  'Warning/Foreground3': palette.orange[70],
  'Warning/Outline': palette.orange[60],
  'Warning/Controlvariant1': palette.orange[50],
  'Warning/Controlvariant2': palette.orange[5],

  'Danger/Base': palette.red[60],
  'Danger/Highlight': palette.red[70],
  'Danger/Background1': palette.red[5],
  'Danger/Background2': palette.red[20],
  'Danger/Foreground1': palette.white,
  'Danger/Foreground2': palette.red[60],
  'Danger/Foreground3': palette.red[70],
  'Danger/Outline': palette.red[70],
  'Danger/Controlvariant1': palette.red[60],
  'Danger/Controlvariant2': palette.red[5],

  /**
   * Accent colors
   */
  'Accent1/Base': palette.blue[60],
  'Accent1/Highlight': palette.blue[70],
  'Accent1/Background1': palette.blue[5],
  'Accent1/Background2': palette.blue[20],
  'Accent1/Foreground1': palette.white,
  'Accent1/Foreground2': palette.blue[60],
  'Accent1/Foreground3': palette.blue[60],
  'Accent1/Outline': palette.blue[60],
  'Accent1/Controlvariant1': palette.blue[60],
  'Accent1/Controlvariant2': palette.blue[5],

  'Accent2/Base': palette.white,
  'Accent2/Highlight': palette.grey[5],
  'Accent2/Background1': palette.grey[5],
  'Accent2/Background2': palette.grey[20],
  'Accent2/Foreground1': palette.black,
  'Accent2/Foreground2': palette.black,
  'Accent2/Foreground3': palette.black,
  'Accent2/Outline': palette.grey[30],
  'Accent2/Controlvariant1': palette.grey[30],
  'Accent2/Controlvariant2': palette.grey[5],

  'Accent3/Base': palette.white,
  'Accent3/Highlight': palette.grey[5],
  'Accent3/Background1': palette.white,
  'Accent3/Background2': palette.white,
  'Accent3/Foreground1': palette.black,
  'Accent3/Foreground2': palette.black,
  'Accent3/Foreground3': palette.black,
  'Accent3/Outline': palette.grey[30],
  'Accent3/Controlvariant1': palette.white,
  'Accent3/Controlvariant2': palette.white,

  'Accent4/Base': palette.green[50],
  'Accent4/Highlight': palette.green[60],
  'Accent4/Background1': palette.green[5],
  'Accent4/Background2': palette.green[20],
  'Accent4/Foreground1': palette.black,
  'Accent4/Foreground2': palette.green[60],
  'Accent4/Foreground3': palette.green[70],
  'Accent4/Outline': palette.green[70],
  'Accent4/Controlvariant1': palette.green[50],
  'Accent4/Controlvariant2': palette.green[5],

  'Accent5/Base': palette.orange[50],
  'Accent5/Highlight': palette.orange[60],
  'Accent5/Background1': palette.orange[5],
  'Accent5/Background2': palette.orange[20],
  'Accent5/Foreground1': palette.black,
  'Accent5/Foreground2': palette.orange[60],
  'Accent5/Foreground3': palette.orange[70],
  'Accent5/Outline': palette.orange[60],
  'Accent5/Controlvariant1': palette.orange[50],
  'Accent5/Controlvariant2': palette.orange[5],

  'Accent6/Base': palette.red[60],
  'Accent6/Highlight': palette.red[70],
  'Accent6/Background1': palette.red[5],
  'Accent6/Background2': palette.red[20],
  'Accent6/Foreground1': palette.white,
  'Accent6/Foreground2': palette.red[60],
  'Accent6/Foreground3': palette.red[70],
  'Accent6/Outline': palette.red[70],
  'Accent6/Controlvariant1': palette.red[60],
  'Accent6/Controlvariant2': palette.red[5],

  'Accent7/Base': palette.purple[60],
  'Accent7/Highlight': palette.purple[70],
  'Accent7/Background1': palette.purple[5],
  'Accent7/Background2': palette.purple[20],
  'Accent7/Foreground1': palette.white,
  'Accent7/Foreground2': palette.purple[60],
  'Accent7/Foreground3': palette.purple[60],
  'Accent7/Outline': palette.purple[70],
  'Accent7/Controlvariant1': palette.purple[60],
  'Accent7/Controlvariant2': palette.purple[5],

  /**
   * Background colors
   */
  'Background/Base': palette.grey[10],
  'Background/Primary': palette.white,
  'Background/Secondary': palette.grey[10],
  'Background/Tertiary': palette.white,
  'Background/Quarternary': palette.white,

  /**
   * Text colors
   */
  'Text/Primary': palette.black,
  'Text/Secondary': palette.grey[60],
  'Text/Tertiary': palette.grey[50],
  'Text/Link': palette.blue[60],
  'Text/Inverted': palette.white,

  /**
   * Misc colors
   */
  'Misc/Divider': palette.grey[30],
  'Divider/Primary': palette.grey[30],
  'Misc/Overlay': transparentize(palette.grey[60], 0.8),
  'Overlay/Primary': transparentize(palette.grey[60], 0.8)
}

export default colors
